import Krinky from "./components/Origin/krinky";

function App() {
  //   const [recipient_email, setEmail] = useState("");
  //   const [subject, setSubject] = useState("");
  //   const [message, setMessages] = useState("");

  //   useEffect(() => {
  //     fetch("http://localhost:5000/send")
  //       .then((res) => res.json())
  //       .then((data) => setEmail(data.message))
  //       .then((data) => setSubject(data.message))
  //       .then((data) => setMessages(data.message));
  //   }, []);

  return <Krinky />;
}

export default App;
